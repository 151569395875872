.createListingPrompt {
    margin-left: 25px;
}

.createListingPrompt button {
    padding: 5px 10px;
    background-color: #FF6B00;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.createListingPrompt button:hover {
    background-color: #e05e00;
}


.createListingPage {
    display: flex;
    text-align: left;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    width: 400px;
    background-color: #ffffff;
    border-radius: 5px;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-bottom: 20px;
}

.createListingPage input {
    padding: 5px 10px;
    border: 1px solid #cccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    margin-top: 5px;
    margin-bottom: 10px;
}

.createListingPage button {
    padding: 5px 10px;
    background-color: #FF6B00;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.createListingPage button:hover {
    background-color: #e05e00;
}

.description [type="text"] {
    width: 100%;
    min-height: 100px;
    max-width: 100%;
    resize: vertical;
}

.input-box [type="text"],
[type=number] {
    width: 100%;
    margin-bottom: 15px;
}

.submit {
    display: flex;
    justify-content: right;
}

.createListingPage img {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 2px;
    margin-top: 3px;
    margin-right: 5px;
    align-self: center;
}

.createListingImg {
    display: flex;
    width: 90%;
    margin: 0 auto;
}

.image-uploads button {
    font-size: 12px;
}

p {
    margin-top: 0px;
    margin-bottom: 20px;
}

h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h2 {
    margin-top: 25px;
    margin-bottom: 10px;
}