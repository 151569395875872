.image-uploads {
    display: flex;
    flex-wrap: wrap;

}

.image-uploads > img {
    width: 20%;
    height: auto;
    object-fit: contain;

}