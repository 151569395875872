.profilePage {
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    padding: 15px;
    margin: 0 auto;
    width: 97%;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}

.selectOption {
    display: flex;
    justify-content: left;
    width: 100%;
}

.inputBoxProfile {
    padding: 5px 10px;
    border: 1px solid #cccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    margin-top: 5px;
    margin-bottom: 10px;

}

.profilePage button {
    padding: 5px 10px;
    background-color: #FF6B00;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.profilePage button:hover {
    background-color: #e05e00;
}

p {
    margin-bottom: 0;
}

h1 {
    margin-top: 0;
    margin-bottom: 15px;
}

.yourListings {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-top: 20px;

}