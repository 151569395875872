.sign-up {
  display: flex;
  text-align: left;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  width: 250px;
  background-color: #ffffff;
  border-radius: 5px;
  padding-right: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}



.Header {
  margin-top: 20px;
  margin-bottom: 0px;
}

.sign-up form {
  margin: 15px;
  text-align: center;
}

.sign-up p {
  margin-top: 20px;
}
.sign-up button {
  padding: 5px 10px;
  background-color: #ff6b00;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sign-up button:hover {
  background-color: #e05e00;
}

.inputBox {
  padding: 5px 10px;
  border: 1px solid #cccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  margin-top: 20px;
}

.submit {
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
  margin-top: 20px;
}

.forgot-password {
  color: black;
  font-size: small;
  text-decoration: underline;
}

.forgot-pass {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  width: 220px;
  background-color: #ffffff;
  border-radius: 5px;
  padding-right: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.recoveryEmail {
  width: 100%;
  padding-left: 12px;
}

.forgot-pass button {
  padding: 5px 10px;
  background-color: #ff6b00;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.forgot-pass button:hover {
  background-color: #e05e00;
}

.forgot-pass-submit {
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
  padding-right: 12px;
}

.search-bar button {
  padding: 5px 10px;
  background-color: #ff6b00;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #e05e00;
}
