.listings {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}

.listing-card {
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    
}

.listing-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
    align-self: center;

}

.listing-card .title {
    font-size: 14px;
    margin-bottom: 5px;
    align-self: left;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

}

.listing-card .price {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    align-self: left

}

.modelsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px
}

.listings-main {
    display: flex
}