.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.search-bar input,
.search-bar select {
  padding: 5px 10px;
  border: 1px solid #cccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.search-bar input[type="text"] {
  width: 200px;
}

.search-bar button {
  
  padding: 5px 10px;
  background-color: #FF6B00;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-bar button:hover {
  background-color: #e05e00;
}


@media screen and (orientation: Portrait) {

  .search-bar {
    gap: 5px;
    margin: 5px 0;
  }
  
  .search-bar input,
  .search-bar select {
    padding: 5px 10px;
    font-size: small;
  }
  
  .search-bar input[type="text"] {
    width: 100px;
  }
  
  .search-bar button {
    padding: 5px 10px;
    font-size: small;
  }


}