.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-page h2 {
    font-size:40px
}
.contact-page p {
    margin: 50px;
}