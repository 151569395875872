.banner {
    display: flex;
    background-color: #FF6B00;
    list-style-type: none;
    width: 100%;
    height: 50px;
    padding: 0px;
    margin-top: 0px;
    justify-content: space-between;
}

.logo-pic {
    object-fit: cover;
    height: 50px;
    width: 80px;
}

.linksContainer {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    font-size: large;
}

.nav-links {
    margin: 10px;
    text-decoration: none;
    align-self: center;
    color: white
}

.nav-links:hover {
    cursor: pointer
}

.link {
    color: white;
}

@media screen and (orientation: Portrait) {

    .logo-pic {
        height: 30px;
        width: 50px;
    }

    .banner {
        height: 30px;
    }

    .nav-links {
        margin: 5px;
    }

    .linksContainer {
        font-size: small;
        padding-left: 0px;
    }



}