.listings-filter {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
}


.makes-models-filter-closed {
    display: none;
}

.show-models {
    padding: 5px 10px;
    background-color: #FF6B00;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.listings-filter button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #FF6B00;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.Filter-Year-Price {
    margin-top: 10px;
}


.Filter-Year-Price input,
.Filter-Year-Price select {
    padding: 5px 10px;
    border: 1px solid #cccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    width: 100%;
    margin-bottom: 10px;
}


@media screen and (orientation: Portrait) {

    h1 {
        font-size: large;
    }

    p {
        font-size: small;
    }

    .show-models {
        font-size: small;
    }

    .listings-filter {
        max-width: 160px;
    }

    .listings-filter button {
        font-size: small;
    }

}