.carousel {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.carousel img {
  width: 100%;
  height: auto;
  max-height: 450px;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.carousel button {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  font-size: 2rem;
  z-index: 1;
}

.carousel button.left {
  left: 1%;
}

.carousel button.right {
  right: 1%;
}

.carousel button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.hideImage {
  display: none;
}