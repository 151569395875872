/* General */
body {
  font-family: Arial, sans-serif;
}

/* ListingDetails */
.listing-details {
  display: flex;
  flex-direction: column;
  align-self: left;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.listing-details h3 {
  font-size: 20px;
  color: #4b4f56;
  margin-top: 10px;
  align-self: left;
}

.listing-details h2 {
  font-size: 28px;
  color: #4b4f56;
  margin-top: 0px;
  align-self: left;
}

.listing-details p {
  font-size: 14px;
  color: #4b4f56;
  align-self: left;
}

/* PartDetails */
.part-details {
  /* padding: 10px;
  display: flex;
  flex-wrap: wrap; */
  /* margin-left: 8%;
  max-width: 15%;
  
  border-radius: 4px;
  margin-top: 5px; */
  background-color: #f0f2f5;
  border-radius: 4px;
}

.part-details h2 {
  font-size: 18px;
  color: #4b4f56;
  margin-bottom: 5px;
}

.part-details p {
  font-size: 14px;
  color: #4b4f56;
}

.details-button {
  padding: 5px 10px;
  background-color: #ff6b00;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.edit-link {
  padding: 5px 10px;
  background-color: #ff6b00;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.listing-details div {
  margin-bottom:5px
}